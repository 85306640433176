import React from 'react';
import { Helmet } from 'react-helmet';
import SEO from '../components/seo/seo';
import Layout from '../components/ui/layout/layout';

const HiddenPageTemplate: React.FC<any> = ({ pageContext, children }) => {
  const layoutConf = {
    siteTitle: pageContext.title,
    hideHeader: false,
    fixedFooter: false,
  };

  return (
    <Layout {...layoutConf}>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <SEO lang="de"></SEO>
      <div className="page-content-wrapper"> {children}</div>
    </Layout>
  );
};

export default HiddenPageTemplate;
