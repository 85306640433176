import HiddenPageTemplate from '../templates/post';
import React from 'react';

const ImprintPage: React.FC = () => {
  return (
    <HiddenPageTemplate pageContext={{ title: 'Impressum' }}>
      <>
        <h2>Angaben gemäß § 5 TMG:</h2>
        <p>Johannes Hinterstößer</p>
        <h3>Postanschrift:</h3>
        <p>
          Setzbergstr. 9<br />
          81539 München
        </p>
        <h3>Kontakt:</h3>
        <p>
          Telefon: 015678 625846
          <br />
          E-Mail: hello@jhinter.de
        </p>
        <h2>Hinweise zur Website</h2>
        <h3>Urheberrechtliche Hinweise</h3>
        <p>
          https://pixabay.com/&nbsp;
          <br />
          https://unsplash.com/
          <br />
        </p>
        <h2>Information gemäß § 36 VSBG</h2>
        <p>
          Gemäß § 36 VSBG (Verbraucherstreitbeilegungsgesetz – Gesetz über die
          alternative Streitbeilegung in Verbrauchersachen) erklärt der
          Betreiber dieser Website:
        </p>
        <p>
          Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <p>
          <em>
            Das Impressum wurde mit dem&nbsp;
            <a href="https://www.activemind.de/datenschutz/impressums-generator/">
              Impressums-Generator der activeMind AG
            </a>
            &nbsp;erstellt.
          </em>
        </p>
      </>
    </HiddenPageTemplate>
  );
};

export default ImprintPage;
